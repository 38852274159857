import tippy, {roundArrow} from 'tippy.js';

document.addEventListener('DOMContentLoaded', () => {

    document.querySelectorAll('[data-tippy-template]').forEach(function(el) {
        const placementVar = (el.dataset.tippyPlacement) ?? 'bottom';
        tippy(el, {
            content(reference) {
                const id = reference.getAttribute('data-tippy-template');
                const template = document.getElementById(id);
                return template.innerHTML;
            },
            allowHTML: true,
            arrow: roundArrow,
            theme: 'light',
            interactive: true,
            appendTo: () => document.body,
            placement: placementVar,
        });
    });

});
